const INPUT_SIZES = {
    medium: {
        labelSize: '16px',
        labelMarginBottom: '12px',
        lineHeight: '24px',
        valueSize: '16px',
        inputPadding: '12px 16px',
        inputPaddingStartAdornment: '12px 16px 12px 12px',
        inputPaddingEndAdornment: '12px 12px 12px 16px',
        inputPaddingWithAdornments: '12px',
        inputHeight: '24px',
        inputAdornmentMargin: '16px',
    },
    small: {
        labelSize: '14px',
        labelMarginBottom: '10px',
        lineHeight: '20px',
        valueSize: '14px',
        inputPadding: '10px 12px',
        inputPaddingStartAdornment: '10px 12px 10px 10px',
        inputPaddingEndAdornment: '10px 10px 10px 12px',
        inputPaddingWithAdornments: '10px',
        inputHeight: '20px',
        inputAdornmentMargin: '12px',
    },
};
export default INPUT_SIZES;
