var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import MuiInputAdornment from '@mui/material/InputAdornment';
import { forwardRef, useCallback, useEffect, useState, } from 'react';
import { animated, useSpring } from '@react-spring/web';
import { useTheme } from '@mui/material';
import InputLabel from '@shared/ui/inputs/InputLabel';
import { BOX_SHADOW_DEFAULT, BOX_SHADOW_ERROR, BOX_SHADOW_FOCUSED, BOX_SHADOW_SUCCESS, defineInputPaddings, getDisabledProps, } from './styles';
import INPUT_SIZES from './constants';
const InputStyled = styled(TextField, {
    shouldForwardProp: (propName) => propName !== 'filled' &&
        propName !== 'fillColor' &&
        propName !== 'hasCounter' &&
        propName !== 'notificationStatus' &&
        propName !== 'hasLabel' &&
        propName !== 'hasStartAdornment' &&
        propName !== 'hasEndAdornment',
})((props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    return (Object.assign({ '& .MuiInputBase-root': {
            width: 'inherit',
            backgroundColor: (props.filled ?
                (props.fillColor || props.theme.customColors.surface.surface) :
                'inherit'),
            paddingLeft: 0,
            paddingRight: 0,
        }, '& .MuiInputAdornment-positionStart': {
            height: 'auto',
            maxHeight: 'unset',
            marginLeft: (_a = INPUT_SIZES[props.size]) === null || _a === void 0 ? void 0 : _a.inputAdornmentMargin,
            marginRight: 0,
        }, '& .MuiInputAdornment-positionEnd': {
            height: 'auto',
            maxHeight: 'unset',
            marginRight: (_b = INPUT_SIZES[props.size]) === null || _b === void 0 ? void 0 : _b.inputAdornmentMargin,
            marginLeft: 0,
        }, '& .MuiOutlinedInput-root.MuiInputBase-root fieldset': {
            border: 'unset',
            boxShadow: 'unset',
        }, '& .MuiInputBase-root.MuiOutlinedInput-root:hover fieldset': {
            border: 'unset',
            boxShadow: 'unset',
        }, '& .MuiOutlinedInput-root.MuiInputBase-root.Mui-focused fieldset': {
            border: 'unset',
            boxShadow: 'unset',
        }, '& .MuiInputBase-input': {
            fontSize: (_c = INPUT_SIZES[props.size]) === null || _c === void 0 ? void 0 : _c.valueSize,
            padding: defineInputPaddings({
                size: props.size,
                hasEndAdornment: props.hasStartAdornment,
                hasStartAdornment: props.hasEndAdornment,
            }),
            lineHeight: (_d = INPUT_SIZES[props.size]) === null || _d === void 0 ? void 0 : _d.lineHeight,
            height: (_e = INPUT_SIZES[props.size]) === null || _e === void 0 ? void 0 : _e.inputHeight,
            color: props.theme.palette.text.primary,
        }, '&.MuiTextField-root.MuiFormControl-root': {
            width: props.fullWidth ? '100%' : 'inherit',
        }, '& label.MuiInputLabel-root': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: props.hasLabel ?
                (_f = INPUT_SIZES[props.size]) === null || _f === void 0 ? void 0 : _f.labelMarginBottom :
                0,
            fontWeight: 500,
            fontSize: (_g = INPUT_SIZES[props.size]) === null || _g === void 0 ? void 0 : _g.labelSize,
            lineHeight: (_h = INPUT_SIZES[props.size]) === null || _h === void 0 ? void 0 : _h.lineHeight,
            color: props.theme.palette.text.primary,
            display: 'flex',
            justifyContent: 'space-between',
        }, '& label.MuiInputLabel-root.Mui-disabled': {
            position: 'relative',
            top: 0,
            left: 0,
            transform: 'none',
            marginBottom: props.hasLabel ?
                (_j = INPUT_SIZES[props.size]) === null || _j === void 0 ? void 0 : _j.labelMarginBottom :
                0,
            fontWeight: 500,
            fontSize: (_k = INPUT_SIZES[props.size]) === null || _k === void 0 ? void 0 : _k.labelSize,
            color: props.theme.palette.text.primary,
        }, '& .MuiInputLabel-asterisk': {
            color: props.required ? props.theme.palette.text.primary : props.theme.palette.error.main,
        }, legend: {
            visibility: 'hidden',
            width: 0,
        }, '& .MuiFormHelperText-root': {
            marginLeft: 0,
            marginTop: props.theme.spacing_sizes.xs,
            fontSize: '14px',
            lineHeight: '18px',
        } }, getDisabledProps(props.theme, props.disabled)));
});
const InputContainer = styled('div', {
    shouldForwardProp: propName => propName !== 'ownerState' &&
        propName !== 'classes'
})(() => ({
    width: '100%',
    borderRadius: 6,
    borderWidth: 1,
    borderStyle: 'solid',
    display: 'flex',
}));
const InputContainerAnimated = animated(InputContainer);
const InputComponent = (_a, ref) => {
    var { filled = false, fillColor = undefined, notification = undefined, notificationStatus = undefined, size = 'small', startAdornment = undefined, startAdornmentProps = {}, endAdornment = undefined, endAdornmentProps = {}, maxCount = undefined, value = undefined, label = undefined, onBlur = undefined, required = undefined, onFocus = undefined } = _a, props = __rest(_a, ["filled", "fillColor", "notification", "notificationStatus", "size", "startAdornment", "startAdornmentProps", "endAdornment", "endAdornmentProps", "maxCount", "value", "label", "onBlur", "required", "onFocus"]);
    const theme = useTheme();
    const [focused, setIsFocused] = useState(false);
    const [{ borderColor, boxShadow }, api] = useSpring(() => ({
        borderColor: theme.customColors.input.border,
        boxShadow: BOX_SHADOW_DEFAULT,
        config: {
            mass: 1,
            tension: 711,
            friction: 40,
        }
    }), []);
    const handleBlur = (ev) => {
        setIsFocused(false);
        if (onBlur) {
            onBlur(ev);
        }
        api.start({
            to: { borderColor: theme.customColors.input.border, boxShadow: BOX_SHADOW_DEFAULT },
        });
    };
    const handleFocus = (ev) => {
        setIsFocused(true);
        if (onFocus) {
            onFocus(ev);
        }
        api.start({
            to: { borderColor: theme.palette.primary.dark, boxShadow: BOX_SHADOW_FOCUSED },
        });
    };
    const handleMouseEnter = () => {
        if (!focused) {
            api.start({
                from: { borderColor: theme.customColors.input.border },
                to: { borderColor: theme.customColors.input.borderHover },
            });
        }
    };
    const handleMouseLeave = () => {
        if (!focused) {
            api.start({
                from: { borderColor: theme.customColors.input.borderHover },
                to: { borderColor: theme.customColors.input.border },
            });
        }
    };
    const defineStatusBoxShadow = useCallback((status) => {
        const startBoxShadow = focused ? BOX_SHADOW_FOCUSED : BOX_SHADOW_DEFAULT;
        switch (status) {
            case 'error':
                api.start({
                    from: { boxShadow: startBoxShadow },
                    to: { boxShadow: BOX_SHADOW_ERROR }
                });
                break;
            case 'success':
                api.start({
                    from: { boxShadow: startBoxShadow },
                    to: { boxShadow: BOX_SHADOW_SUCCESS },
                });
                break;
            default:
                api.start({
                    from: { boxShadow: startBoxShadow },
                    to: { boxShadow: focused ? BOX_SHADOW_FOCUSED : BOX_SHADOW_DEFAULT },
                });
                break;
        }
    }, [focused, api]);
    useEffect(() => {
        defineStatusBoxShadow(notificationStatus);
    }, [notificationStatus, defineStatusBoxShadow]);
    return (_jsx(InputStyled, Object.assign({}, props, { label: (_jsx(InputLabel, { count: value === null || value === void 0 ? void 0 : value.length, focused: focused, label: label, maxCount: maxCount, size: size, required: required })), hasLabel: typeof label !== 'undefined', value: value, size: size, variant: 'outlined', notificationStatus: notificationStatus, filled: !!filled, fillColor: fillColor, helperText: notification, error: notificationStatus === 'error', InputProps: {
            slots: {
                root: InputContainerAnimated,
            },
            slotProps: {
                root: {
                    style: {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore 
                        borderColor: borderColor.to((border) => border),
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore 
                        boxShadow: boxShadow.to((boxShadowVal) => `${boxShadowVal}`),
                    }
                }
            },
            startAdornment: startAdornment ? (_jsx(MuiInputAdornment, Object.assign({}, startAdornmentProps, { position: 'start', children: startAdornment }))) : undefined,
            endAdornment: endAdornment ? (_jsx(MuiInputAdornment, Object.assign({}, endAdornmentProps, { position: 'end', children: endAdornment }))) : undefined,
        }, hasStartAdornment: !!startAdornment, hasEndAdornment: !!endAdornment, onBlur: handleBlur, onFocus: handleFocus, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, ref: ref })));
};
const Input = forwardRef(InputComponent);
export default Input;
